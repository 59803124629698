import React, {useState, useEffect} from 'react';
import '../assets/stylesheets/components.css';
import IconNav from '../components/iconNav';
import ConsultServiceSlider from '../components/consulting-services-slider';
import Prismic from 'prismic-javascript';
import {Client} from '../prismic-configuration';


const ConsultServices = (props) => {
    const [doc, setDocData] = useState(null);
		
    useEffect(() => {
        
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'property_consulting_services')
            )
        
            if (response) {
                setDocData(response.results);
            }
        }
        fetchData();  
    }, []);

    if(doc) {
        return(
            <div className="services-container">
                <IconNav/>
                <ConsultServiceSlider tab={props.location.linkProps} service={doc}/>
            </div>
        );
    } return null;
}


export default ConsultServices;