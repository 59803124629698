import React, {useState} from 'react';
import HomeNav from '../components/homeNav';
import Contact from '../components/contact';
import '../assets/stylesheets/components.css';
import LogoAnim from '../components/LogoAnim';


const Home = () => {
    const [logo, setLogo] = useState(false);
    const animate = () => {
        setLogo(true);
    }

    setTimeout(animate, 2000);

    return (
        <div className="home-container">
            <div className="home-logo"></div>
            <h1 style={{display: 'none'}}>Legionaire property specialists</h1>
            <div className="filter">
                <div className="menu-animation-container" onClick={animate}>
                    <LogoAnim></LogoAnim>
                    <HomeNav visible={logo}/>
                    <Contact/>
                </div>
                
            </div>
        </div>
    );
}

export default Home;