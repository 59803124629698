import React, {useState} from 'react';
import '../assets/stylesheets/navComponents.css';
import Nav from './nav';
import {Link} from 'react-router-dom';

const IconNav = () => {

    const[menu, setMenu] = useState(false);
   
    const iconNav = menu ? "nav-icon-menu icon-menu-expand-x" : "nav-icon-menu";
    const iconNavBtn = menu ? "icon-legionaire_x-icon icon-btn icon-active" : "icon-legionaire_hamburger-icon icon-btn";
    const toggleNav = () => {
        setMenu(!menu);
    }

    return (
        <div>
            <i className={iconNavBtn} onClick={toggleNav}></i>
            <div className={iconNav}>
                <Link onClick={toggleNav} to={"/"}>
                    <div className="home-logo iconNav-logo"></div>
                </Link>
                <div className="icon-nav-container-container">
                    <div className="icon-nav-container">
                        <Nav toggle={toggleNav}/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default IconNav;