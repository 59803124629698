import Prismic from 'prismic-javascript'

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
export const apiEndpoint = process.env.REACT_APP_PRISMIC_API_ENDPOINT;

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
const accessToken = process.env.REACT_APP_PRISMIC_ACCESS_TOKEN;

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  if (doc.type === 'project') return `/projects/${doc.uid}`
  return '/'
}

// Client method to query documents from the Prismic repo
export const Client = Prismic.client(apiEndpoint, { accessToken })
