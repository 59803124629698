import React, {useState, useEffect} from 'react';
import Nav from '../components/nav';
import '../assets/stylesheets/components.css';
import Prismic from 'prismic-javascript';
import {RichText } from 'prismic-reactjs';
import {Client} from '../prismic-configuration';


const HomeMobile = () => {
        const [doc, setDocData] = useState(null);
        
        useEffect(() => {  
            const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'contact_details')
            )
            
            if(response) {
            setDocData(response.results[0]);
            }     
        }    
        fetchData();    
        }, []);

    return (
        <div className="home-container home-container-mobile">
            <h1 style={{display: 'none'}}>Legionaire property specialists</h1>
            <div className="filter">
                <div className="mobile-header">
                </div>
                <div className="mobile-nav-container">
                    <Nav/>
                </div>
            </div>
        </div>
    );
}
export default HomeMobile;