import React, {useState, useEffect} from 'react';
import '../assets/stylesheets/navComponents.css'
import Prismic from 'prismic-javascript';
import {RichText } from 'prismic-reactjs';
import {Client} from '../prismic-configuration';

const Contact = () => {
    const [doc, setDocData] = useState(null);
    const [classToggle, setClassToggle] = useState(false);
    const toggleClass = () => {
        setClassToggle(!classToggle);
    }
    const contactClass = classToggle ? 'contact-container expand-x' : 'contact-container';
    
    useEffect(() => {
                
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'contact_details')
            )
            
            if (response) {
                setDocData(response.results[0]);
            }
            
        }    
        fetchData();    
    }, []);
    
    return (
        <div className={contactClass}>
            <h5 className="contact-btn" onClick={toggleClass}>contact</h5>
    {doc ? (
            <div className="contact-info">
            {doc.data.email ? (
                    <a
                        href = {`mailto: ${RichText.asText(doc.data.email)}`}>
                        <p>{RichText.asText(doc.data.email)}</p>
                    </a>
                ) :null 
            }
            {doc.data.contact_number ? (
                <a
                    href = {`tel: ${RichText.asText(doc.data.contact_number)}`}>
                    <p>{RichText.asText(doc.data.contact_number)}</p>
                </a> ): null
            }

                <p>
                    {doc.data.instagram ? (
                        <a 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            href={RichText.asText(doc.data.instagram)}>
                                <i className="icon-instagram-brands-icon"></i>
                        </a>):null
                    }
                    {doc.data.facebook ? (
                    <a  
                        target="_blank" 
                        rel="noopener noreferrer" 
                        href={RichText.asText(doc.data.facebook)}>
                            <i className="icon-facebook-brands-icon"></i>
                    </a>):null
                    }
                    {doc.data.google_maps_location ? (
                        <a  
                            target="_blank" 
                            rel="noopener noreferrer" 
                            href={RichText.asText(doc.data.google_maps_location)}>
                                <i className="icon-legionaire_map-icon"></i>
                        </a>):null
                    }
                </p>
            </div>): <div></div>
            }
        </div>
    );
}

export default Contact;