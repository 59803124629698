import React, {useState, useEffect} from 'react';
import '../assets/stylesheets/components.css';
import ArchiServiceSlider from '../components/archi-services-slider';
import IconNav from '../components/iconNav';
import Prismic from 'prismic-javascript';
import {Client} from '../prismic-configuration';


const ArchiServices = (props) => {
        const [doc, setDocData] = useState(null);

        useEffect(() => {
            const fetchData = async () => {

                const response = await Client.query(
                    Prismic.Predicates.at('document.type', 'architectural_design_services')
                )
                
                if (response) {
                    setDocData(response.results);
                }  
            }    
            fetchData();   
        }, []);
            
        if(doc) {
            return (
                <div className="services-container">
                    <IconNav/>
                    <ArchiServiceSlider tab={props.location.linkProps} service={doc}/>
                </div>
            );
        } return null;
    }



export default ArchiServices;