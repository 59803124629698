import React, { useEffect, useState }  from 'react';
import {Client} from '../prismic-configuration'
import '../assets/stylesheets/components.css';
import ImageSlider from '../components/image-slider';
import Project from '../components/project';
import IconNav from '../components/iconNav';
import Contact from '../components/contact';

const Fullproject = (props) => {
  const [doc, setDocData] = useState(null)
  const uid = props.match.params.uid;
  const [isMobile, setMobile] = useState(window.innerWidth < 1024);

  const updateMedia = () => {
    setMobile(window.innerWidth < 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await Client.getByUID('project', uid)
      
      if(result) {
        return setDocData(result)
      } 
    }
    fetchData();
  }, [uid]) ;
  
   if(doc) { return (  
        <div>          
              <div className="full-project-container">
                  <IconNav/>
                  <ImageSlider project={doc}/>
                  <Project project={doc}/>                
              </div>
              {
                !isMobile ? (
                  <div className="page_contact_container">
                    <Contact/>
                  </div>
                ): null
              }
              
          </div>  
              )
            } return null;
}


export default Fullproject;