import React, {useState} from 'react';
import '../assets/stylesheets/components.css';


const ImageSlider = (props) => {
    const {main_image, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15} = props.project.data;
    let images = [main_image, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15];
    images = images.filter(el => el.url);

    const[x, setX] = useState(0);
    const nextImg = () => {
        (x=== -100*(images.length-1))? setX(0) : setX(x-100);
    };
    const prevImg = () => {
        (x >= 0)? setX(0) :setX(x+100);
    };

    return(
        <div className="image-slider">
            <div className="images-container" style={{width: (images.length*100)+'%'}}>

                   { images.map((item, index) => {
                       return (
                           <img key={index} src={item.url} alt="" className="image-slider-image" style={{transform: `translateX(${x}%)`,
                                                                                                     width: (100/images.length)+'%'}}/>
                       );
                   })}
                <div className="image-slider-buttons">
                    <button onClick={prevImg} className="image-slider-prev"><i className="icon-legionaire_prev-icon"></i></button>
                    <button onClick={nextImg} className="image-slider-next"><i className="icon-legionaire_next-icon"></i></button>
                </div>
            </div>
        </div>
    );
}

export default ImageSlider;