import React, {useState, useEffect} from 'react';
import '../assets/stylesheets/components.css';
import GalleryItem from '../components/gallery-item';
import IconNav from '../components/iconNav';
import Prismic from 'prismic-javascript';
import {Client} from '../prismic-configuration';
import {Link} from 'react-router-dom';


const Gallery = () => {

  const [doc, setDocData] = useState(null);
  const	[selectedItemKey, setSelectedItemKey] = useState(null);
  const [spacers, setSpacers] = useState(0);
	
  const width  = window.innerWidth;
  const columns = width > 1050 ? 3: 2;

  useEffect(() => {

    const fetchData = async () => {
      
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'project')
      )
    
      if (response) {
        setDocData(response.results);
        setSpacers(() => (response.results.length % columns));
        console.log(response.results.length % columns);
      }
    }
    
    fetchData();
    
  }, []);

    if(doc) {
      
      let teaser = doc.map(project =>({id: project.id, image: project.data.main_image}));
      let image = teaser.find(el => el.id === selectedItemKey);
      let bg = selectedItemKey ? image.image.url : '';

      return  (
          <div className={`gallery-container ${selectedItemKey ? 'gallery-filter' : ''}`} style={{backgroundImage: `url(${bg})`}}>
              <IconNav/>
              <div className="gallery-item-container">
                  {
                      doc.map(item => {
                          return (
                            <Link 
                              key={item.id} 
                              onMouseEnter={() => setSelectedItemKey(item.id)} 
                              onMouseLeave={() => setSelectedItemKey(null)} 
                              className={selectedItemKey === item.id ? '' : selectedItemKey ? 'fade' : ''} 
                              to={`/projects/${item.uid}`}>
                              <GalleryItem project={item}/>
                            </Link>
                          )
                      })
                  }
                  {
                    spacers ? (
                      [...Array(spacers)].map((spacer, i) => {
                        return ( <div className="gallery-item" key={i}></div>)  
                      })): null
                  }
              </div>
          </div>
      );

    } return null;
}




export default Gallery;
