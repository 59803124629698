import React, {useEffect, useState, Fragment} from 'react';
import '../assets/stylesheets/navComponents.css';
import {Link} from 'react-router-dom';
import {RichText } from 'prismic-reactjs';
import {Client} from '../prismic-configuration';

const Nav = ({toggle}) => {

    const [menu, setMenu] = useState({
        "propMenu": false,
        "archMenu": false, 
        "projMenu": false, 
        "contactMenu": false, 
    });
    const [isMobile, setMobile] = useState(window.innerWidth < 1024);

    const updateMedia = () => {
      setMobile(window.innerWidth < 1024);
    };

    const expandMenu = (subMenu) => {
        const keys = Object.keys(menu);
        const updateState = {...menu}    
        keys.forEach((key)=>{
            if(key == subMenu){
                if(updateState[key] === true) {
                updateState[key] = false;
                } else {
                    updateState[key] = true; 
                }
            } else {
                updateState[key] = false;
            }
           
        })
        setMenu(updateState);
    }
 
  
    const toggleProp = menu.propMenu ? "dropdown-menu-nav dropdown-menu-nav-expand" : "dropdown-menu-nav";
    const toggleArch = menu.archMenu ? "dropdown-menu-nav dropdown-menu-nav-expand" : "dropdown-menu-nav";
    const toggleProj = menu.projMenu ? "dropdown-menu-nav dropdown-menu-nav-expand" : "dropdown-menu-nav";
    const toggleContact = menu.contactMenu ? "dropdown-menu-nav dropdown-menu-nav-expand" : "dropdown-menu-nav";
    const [doc, setDocData] = useState(null);

    useEffect(() => {       
        const fetchData = async () => {
            const response = await Client.query();
            if (response) {
                setDocData(response.results);
            }   
        }
            
        fetchData();
            
    }, []);

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
      });

    

 if(doc){
    const consult = doc.filter(item => item.type === "property_consulting_services");
    const design = doc.filter(item => item.type === "architectural_design_services");
    let project = doc.filter(item => item.type === "project");
    project.sort((a,b)=>Date.parse(b.first_publication_date) - Date.parse(a.first_publication_date) );
    project = project.filter((item, index) => index < 3);
    const contact_details = doc.filter(item => item.type === "contact_details")[0];

    return (
        <nav className="nav">
                <ul className="navbar">
                    <li>
                        <div onClick={() => {expandMenu("propMenu");}} className="nav-item nav-item-property nav-icon-item">Property</div>
                        <div className={toggleProp}>
                            {consult.map((item, index) => {return (
                                <Link onClick={toggle} key={item.id} to={{pathname: "/property_consulting", 
                                                         linkProps: index
                                                        }}>
                                    <div className="drop-down-menu-item nav-icon-menu-drop-down">{RichText.asText(item.data.consulting_service)}</div>
                                </Link>
                            )})}
                        </div>
                    </li>
                    <li>
                        <div onClick={() => {expandMenu("archMenu")}} className="nav-item nav-item-architectural nav-icon-item">Architectural</div>
                        <div className={toggleArch}>
                             {design.map((item, index) => {return (
                                <Link onClick={toggle} key={item.id} to={{pathname: "/architectural_design", 
                                                         linkProps: index
                                                         }}>
                                    <div className="drop-down-menu-item nav-icon-menu-drop-down">{RichText.asText(item.data.architectural_service)}</div>
                                </Link>
                            )})}
                        </div>
                    </li>
                    <li>
                        <div onClick={() => {expandMenu("projMenu")}} className="nav-item nav-item-project nav-icon-item">Project</div>
                        <div className={toggleProj}>
                        {project.map(item => {return (
                                <Link onClick={toggle} key={item.id} to={`/projects/${item.uid}`}><div className="drop-down-menu-item nav-icon-menu-drop-down">{RichText.asText(item.data.project_name)}</div></Link>
                            )})}
                        <Link onClick={toggle} to="/projects"><div className="drop-down-menu-item nav-icon-menu-drop-down all-projects">all projects</div></Link>
                        </div>
                    </li>
                     {
                        isMobile ? (
                            <Fragment>
                            <li>
                                <div onClick={() => {expandMenu("contactMenu")}} 
                                    className="nav-item nav-item-contact nav-icon-item">Contact</div>
                                <div className={toggleContact}> 
                                    {contact_details.data.email ? (
                                        <a 
                                            onClick={toggle}
                                            href = {`mailto: ${RichText.asText(contact_details.data.email)}`}>
                                            <div className="drop-down-menu-item nav-icon-menu-drop-down">
                                                {RichText.asText(contact_details.data.email)}
                                            </div>
                                        </a> ) : null
                                    }
                                    {contact_details.data.contact_number ? (
                                        <a 
                                            onClick={toggle}
                                            href = {`tel: ${RichText.asText(contact_details.data.contact_number)}`}>
                                            <div className="drop-down-menu-item nav-icon-menu-drop-down">
                                                {RichText.asText(contact_details.data.contact_number)}
                                            </div>
                                        </a> ): null
                                    }
                                    {contact_details.data.instagram ? (
                                        <a 
                                            onClick={toggle}
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            href={RichText.asText(contact_details.data.instagram)}>
                                            <div className="drop-down-menu-item nav-icon-menu-drop-down">
                                                instagram
                                                <i className="icon-instagram-brands-icon contact_menu_icon"></i>
                                            </div>
                                        </a> ) : null
                                    }
                                    {contact_details.data.facebook ? (
                                        <a 
                                            onClick={toggle}
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            href={RichText.asText(contact_details.data.facebook)}>
                                            <div className="drop-down-menu-item nav-icon-menu-drop-down">
                                                facebook
                                                <i className="icon-facebook-brands-icon contact_menu_icon"></i>
                                            </div>
                                        </a> ):null
                                    }
                                    {contact_details.data.google_maps_location ? (
                                        <a 
                                            onClick={toggle}
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            href={RichText.asText(contact_details.data.google_maps_location)}>
                                            <div className="drop-down-menu-item nav-icon-menu-drop-down">
                                                location
                                                <i className="icon-legionaire_map-icon contact_menu_icon"></i>
                                            </div>
                                        </a> ):null
                                }
                                </div>
                            </li>
                             <li>
                             <div className="nav-item nav-item-home nav-icon-item">
                                <Link onClick={toggle} to={"/"}>Legionaire</Link>
                             </div>
                             </li>
                            </Fragment>
                        ) : null
                    }
                </ul>
            </nav>
        );
     } return null;
}

export default Nav;