import React, {useState, useEffect} from 'react';
import '../assets/stylesheets/components.css';
import '../assets/stylesheets/navComponents.css';
import Prismic from 'prismic-javascript';
import {RichText } from 'prismic-reactjs';
import {Client} from '../prismic-configuration';

const ConsultServiceSlider = ({tab, service}) => {
    const [doc, setDocData] = useState(null);
    const[x, setX] = useState(tab*-100);
    const [selectedItemIndex, setSelectedItemIndex] = useState(tab);
    const serviceNav = (index) => {
        setX(index*-100);
    };
    useEffect(() => {     
            const fetchData = async () => {
                const response = await Client.query(
                    Prismic.Predicates.at('document.type', 'contact_details')
                )
                
                if (response) {
                    setDocData(response.results[0]);
                }    
            }   
            fetchData();   
        }, []);
        
    return (
        <div className="service-slider-container"> 
            <div className="sub-nav">
                <ul>
                    {service.map((item, index) => {
                        return (
                        <li onClick={() => {serviceNav(index); setSelectedItemIndex(index)}} key={item.id} className={`sub-nav-item ${selectedItemIndex===index?'active':''}`} >{RichText.asText(item.data.consulting_service)} </li>
                        );
                    })}
                </ul>
            </div>
            <div className="service-background">
            <div className="service-slider">
                <div className="service-container" style={{width: (service.length*100)+'%'}}>

                    {service.map((item, index) => {
                        return (
                            <div key={index} className="service-details" style={{transform: `translateX(${x}%)`,
                                                                                 width: (100/service.length)+'%'}}>
                                <div className="service-details-container">
                                <div className="service-header">
                                        <h3>{RichText.asText(item.data.consulting_service)} </h3>
                                    </div>
                                    <div className="service-description">
                                        {RichText.asText(item.data.description)}
                                    </div>

                                    <div className="service-contact">
                                        <h5>contact us</h5>
                                        { doc ? (<div className="service-contact-info">
                                                        { doc.data.email ? (
                                                            <p>
                                                                <a
                                                                    href = {`mailto: ${RichText.asText(doc.data.email)}`}>
                                                                    {RichText.asText(doc.data.email)}
                                                                </a>
                                                            </p>
                                                            ) :null
                                                            }
                                                            <p>
                                                                {doc.data.contact_number ? (
                                                                    <a
                                                                        href = {`tel: ${RichText.asText(doc.data.contact_number)}`}>
                                                                        {RichText.asText(doc.data.contact_number)}
                                                                    </a>
                                                                ): null
                                                                }
                                                                { doc.data.instagram ? (
                                                                    <a
                                                                        target="_blank" 
                                                                        rel="noopener noreferrer" 
                                                                        href={RichText.asText(doc.data.instagram)}>
                                                                        <i className="icon-instagram-brands-icon"></i>
                                                                    </a> ):null
                                                                }
                                                                {doc.data.facebook ? (
                                                                    <a  
                                                                        target="_blank" 
                                                                        rel="noopener noreferrer" 
                                                                        href={RichText.asText(doc.data.facebook)}>
                                                                            <i className="icon-facebook-brands-icon"></i>
                                                                    </a>) : null
                                                                    }
                                                                {doc.data.google_maps_location ? (
                                                                    <a  
                                                                        target="_blank" 
                                                                        rel="noopener noreferrer" 
                                                                        href={RichText.asText(doc.data.google_maps_location)}>
                                                                        <i className="icon-legionaire_map-icon"></i>
                                                                    </a> ) : null
                                                                }
                                                            </p>
                                                    </div>): <div></div>}
                                    </div>
                                    </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            </div>
        </div>
    );
}


export default ConsultServiceSlider;