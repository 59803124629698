import React, {useState, useEffect} from 'react';
import Prismic from 'prismic-javascript';
import {Client} from './prismic-configuration';
import './assets/stylesheets/App.css';
import Fullproject from './pages/project-page';
import Home from './pages/home';
import HomeMobile from './pages/home-mobile';
import ArchiServices from './pages/archi-services-page';
import Gallery from './pages/gallery-page';
import ConsultServices from './pages/consulting-services-page';
import NotFound from './pages/NotFound';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';


function App() {
  const [doc, setDocData] = useState(null);
	const randomGen = () => {
    return Math.floor(Math.random()*100000);
  }
 
	
  useEffect(() => {
      
  const fetchData = async () => {
      
      const response = await Client.query(
      
      Prismic.Predicates.at('document.type', 'background')
      
      )
      
      if (response) {
      
      setDocData(response.results[0]);
      
      }
      
  }
      
  fetchData();
      
  }, []);
  const [isMobile, setMobile] = useState(window.innerWidth < 1024);

  const updateMedia = () => {
    setMobile(window.innerWidth < 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const responsive = isMobile ? HomeMobile : Home;

if(doc) {
  const bg = doc.data.background_image.url;
  return (
    <Router>
      <div className="App" style={{backgroundImage: `url(${bg})`}} >
        <Switch>
          <Route exact path="/" component={responsive}/>
          <Route exact path="/property_consulting" 
            render={(props) => <ConsultServices {...props} key={randomGen()}/>}
          />
          <Route exact path="/architectural_design" 
          render={(props) => <ArchiServices {...props} key={randomGen()}/>}
          />
          <Route exact path="/projects" component={Gallery}/>
          <Route exact path="/projects/:uid" component={Fullproject}/>
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
} return null;
}
export default App;
