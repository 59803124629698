import React from 'react';
import '../assets/stylesheets/components.css';
import {RichText } from 'prismic-reactjs';
import {Link} from 'react-router-dom';
const Project = ({project}) => {

    return (
        <div className="project-details">
            <div className="project-header">
                <h3>{RichText.asText(project.data.project_name)}</h3>
                <h5>{RichText.asText(project.data.area)}, <span>{RichText.asText(project.data.city)}</span>
                </h5>
            </div>
            <div className="project-sub-header">
                <h5>client: <span className="project-client">{RichText.asText(project.data.client)}</span></h5>
                <h5>completion: <span>{RichText.asText(project.data.completion)}</span></h5>
            </div>
            <div className="project-description">
                <div>{RichText.asText(project.data.description)} </div>
            </div>
            <Link to="/projects"><button className="gallery-link">all projects<i className="icon-legionaire_next-icon"></i></button></Link>  
        </div>

    );

}






export default Project;