import React, {useEffect, useState} from 'react';
import '../assets/stylesheets/navComponents.css';
import {Link} from 'react-router-dom';
import {RichText } from 'prismic-reactjs';
import {Client} from '../prismic-configuration';

const HomeNav = (props) => {
    const menuClass = props.visible ? "home-nav expand-y" : "home-nav";
    const [doc, setDocData] = useState(null);
    const [menu, setMenu] = useState({
        "propMenu": false,
        "archMenu": false, 
        "projMenu": false
    });

    const expandMenu = (subMenu) => {
        const keys = Object.keys(menu);
        const updateState = {...menu}    
        keys.forEach((key)=>{
            if(key == subMenu){
                if(updateState[key] === true) {
                updateState[key] = false;
                } else {
                    updateState[key] = true; 
                }
            } else {
                updateState[key] = false;
            }
           
        })
        setMenu(updateState);
    }

        const toggleProp = menu.propMenu ? "dropdown-menu dropdown-menu-expand" : "dropdown-menu";
        const toggleArch = menu.archMenu ? "dropdown-menu dropdown-menu-expand" : "dropdown-menu";
        const toggleProj = menu.projMenu ? "dropdown-menu dropdown-menu-expand" : "dropdown-menu";

    useEffect(() => {      
        const fetchData = async () => {
            const response = await Client.query();
            if (response) {
                setDocData(response.results);
            }   
        } 
        fetchData();
            
    }, []);

 if(doc){

    const consult = doc.filter(item => item.type === "property_consulting_services");
    const design = doc.filter(item => item.type === "architectural_design_services");
    let project = doc.filter(item => item.type === "project");
    project.sort((a,b)=>Date.parse(b.first_publication_date) - Date.parse(a.first_publication_date) );
    project = project.filter((item, index) => index < 3);
    
    return (
        <nav className={menuClass}>
                <ul className="navbar">
                    <li className="dropdown-property">
                       <div onClick={() => {expandMenu("propMenu");}} className="nav-item nav-item-property">Property</div>
                        <div className={toggleProp}>
                            {consult.map((item, index) => {return (
                                <Link key={item.id} to={{pathname: "/property_consulting", 
                                                        linkProps: index
                                                         }}>
                                   <div className="drop-down-menu-item">{RichText.asText(item.data.consulting_service)}</div>
                                </Link>
                            )})}
                        </div>
                    </li>
                    <li className="dropdown-architectural">
                    <div  onClick={() => {expandMenu("archMenu");}}className="nav-item nav-item-architectural">Architectural</div>
                    <div className={toggleArch}>
                             {design.map((item, index) => {return (
                                <Link key={item.id} to={{pathname: "/architectural_design", 
                                                        linkProps: index
                                                        }}>
                                   <div className="drop-down-menu-item">{RichText.asText(item.data.architectural_service)}</div>
                                </Link>
                            )})}
                        </div>
                    </li>
                    <li className="dropdown-project">
                    <div  onClick={() => {expandMenu("projMenu");}} className="nav-item nav-item-project">Project</div>
                        <div className={toggleProj}>
                        {project.map(item => {return (
                                <Link key={item.id} to={`/projects/${item.uid}`}><div className="drop-down-menu-item">{RichText.asText(item.data.project_name)}</div></Link>
                            )})}
                        <Link to="/projects"><div className="drop-down-menu-item all-projects">all projects</div></Link>
                        </div>
                    </li>
                </ul>
            </nav>

        );
     } return null;
}

export default HomeNav;