import React from 'react';
import '../assets/stylesheets/components.css';
import {RichText } from 'prismic-reactjs';

const GalleryItem = (props) => {

    return (
        <div className="project-header gallery-item">
            <h3>{!(RichText.asText(props.project.data.project_name)) ? 'untitled project' : RichText.asText(props.project.data.project_name)}</h3>
            <h5>{(RichText.asText(props.project.data.area)) ? `${RichText.asText(props.project.data.area)},` : ''} <span>{RichText.asText(props.project.data.city)}</span></h5>
        </div> 
    );
}

export default GalleryItem;